// import React from 'react';
// import { AnimatePresence } from 'framer-motion';

import './src/styles/variables.scss';
import './src/styles/normalize.scss';
import './src/styles/print.scss';
import './src/styles/global.scss';

// export const wrapPageElement = ({ element }) => (
//     <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
// );
